import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";

function Rut() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [payment, setPayment] = useState(null);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    //const [isShow, setShow] = useState(false);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/paymentus`, {})
            .then((res) => {
                setPayment(res.data.data);
            })
            .catch((err) => function () {});
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);

    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Lịch sử rút tiền</h1>
            </div>
            <div className="main">
                {payment ? (
                    <div className="content-history" style={{ margin: "0.2rem 0 0" }}>
                        <AnimatedPage>
                            {payment?.map((item, key) => (
                                <>
                                    {item?.type_payment === "RÚT" ? (
                                        <>
                                            <div className="item_inner">
                                                <div className="item_history">
                                                    <div className="title_item_history">
                                                        <span className="sanh"> {item?.type_payment}</span>
                                                        <span
                                                            className={`type_state ${
                                                                item?.status_payment === "Pending" ? "pending" : item?.status_payment === "Success" ? "win" : "lose"
                                                            }`}>
                                                            {item?.status_payment === "Pending" ? "Chờ xử lý" : item?.status_payment === "Success" ? "Thành công" : "Thất bại"}
                                                        </span>
                                                    </div>
                                                    <div className="id_history_sanh">STK : {item?.detail}</div>
                                                </div>
                                                <div className="money_history">
                                                    <span className="money">
                                                        {item?.status_payment === "Pending" ? "-" : item?.status_payment === "Success" ? "-" : ""}
                                                        {Number(item?.money).toLocaleString()}₫
                                                    </span>
                                                    <div className="time_choose">{formatDate(new Date(item?.createdAt))}</div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </>
                            ))}
                        </AnimatedPage>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
            <Footer profile={profile} />
        </>
    );
}
export default Rut;
