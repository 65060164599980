import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import AnimatedPage from "../components/AnimatedPage";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";

function Login() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
    }, []);
    const onSubmit = async (data) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username: data.username.toLowerCase(),
                password: data.password
            })
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("data");
                    localStorage.setItem("user", res.data.data);
                    navigate("/");
                });
            })
            .catch((err) => setErr(err.response.data.message ? err.response.data.message : "Tên đăng nhập hoặc mật khẩu không chính xác!"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="login">
            <div style={{ minHeight: "50px" }}>
                {notify && (
                    <>
                        {notify[1]?.isShow && (
                            <div className="banner-login">
                                <img alt="" src={notify[1]?.image} />
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="logo-login">
                <img alt="" src="/images/logo.png" />
            </div>
            <AnimatedPage>
                <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                    <div onClick={() => navigate("/register")} className="login-return">
                        <KeyboardArrowLeftOutlinedIcon className="login-return-i" /> <span>Quay lại</span>
                    </div>
                    <div className="inputs" style={{ marginTop: "0.5rem" }}>
                        <div className="input">
                            <img alt="" src="images/icon-user.svg" />
                            <input type="text" className="ip-lg" {...register("username", { required: true })} placeholder="Tên đăng nhập" />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div className="input">
                            <img alt="" src="images/icon-password.svg" />
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Mật khẩu" />
                            {showPassword ? <Visibility onClick={toggleShowPassword} /> : <VisibilityOff onClick={toggleShowPassword} />}
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                    </div>
                    {err ? <p>{err}</p> : null}
                    <div className="p-lg">
                        {/*<span>Quên mật khẩu?</span>*/}
                        <Link to="/register">
                            <i>Bạn chưa có tài khoản?</i> Đăng ký
                        </Link>
                    </div>
                    <button className="btn-red-big" type="submit">
                        ĐĂNG NHẬP
                    </button>
                </form>
            </AnimatedPage>
        </div>
    );
}
export default Login;
