import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import { Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow, Pagination, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

function HistoryCasino() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [data, setData] = useState(null);
    const [load, setLoad] = useState(true);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [searched, setSearched] = useState("");
    const [pages, setPage] = useState(1);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = JSON.parse(localStorage.getItem("historyapi")).filter((row) => {
                console.log(row)
                const a = row?.member?.toLowerCase().includes(searchedVal.toString().toLowerCase());
                const b = Number(row?.user?.iduser).toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const c = row?.detail?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                return a;
            });
            setData(filteredRows);
        } else {
            setData(JSON.parse(localStorage.getItem("historyapi")));
        }
    };
    const handleChange = (e, page) => {
        setPage(page);
    };
    useEffect(() => {
        if (load === true) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/casino/history`, {})
                .then((res) => {
                    localStorage.setItem("historyapi", JSON.stringify(res.data.data));
                    setData(res.data.data);
                    console.log(res.data.data);
                    setLoad(false);
                })
                .then((res) => setLoad(true));
        }
    }, [load]);
    if (data !== null) {
        return (
            <>
                <ThemeProvider theme={theme}>
                    <DashboardLayout>
                        {
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    py: 1
                                }}>
                                <Container maxWidth={false}>
                                    <div className="container_set">Lịch sử API</div>
                                    <div className="form_set">
                                        <Box sx={{ minWidth: 600 }}>
                                            <TextField
                                                value={searched}
                                                onChange={(searchVal) => requestSearch(searchVal.target.value)}
                                                placeholder="Tìm kiếm"
                                                sx={{ marginBottom: "5px", paddingRight: "700px" }}
                                            />
                                            <Table sx={{ width: 1200 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ padding: "10px" }}>ID gd</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Tài khoản</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Số tiền</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Tiền thắng</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Nhà cung cấp</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Loại trò chơi</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Trạng thái</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Thời gian</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {data && (
                                                    <TableBody>
                                                        {data?.slice((pages - 1) * 20, (pages - 1) * 20 + 20).map((item) => (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.id}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.member}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.bet.toLocaleString()}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.payout.toLocaleString()}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.site}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.product}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                        {item?.status == 1 ? "Hoàn thành" : item?.status == 0 ? "Chưa hoàn thành" : "Lỗi"}
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item?.match_time))}</TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}
                                                    </TableBody>
                                                )}
                                            </Table>
                                        </Box>
                                    </div>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            pt: 3
                                        }}>
                                        {data != null ? <Pagination color="primary" count={Math.floor(data.length / 20) + 1} size="small" onChange={handleChange} /> : null}
                                    </Box>
                                </Container>
                            </Box>
                        }
                    </DashboardLayout>
                </ThemeProvider>
            </>
        );
    }
}
export default HistoryCasino;
