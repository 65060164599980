import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import AnimatedPage from "../components/AnimatedPage";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import PhoneIcon from "@mui/icons-material/Phone";

function Register() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const navigate = useNavigate();
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
    }, []);
    const onSubmit = (data) => {
        const phoneNumberPattern = /^(0\d{9,10})$/;
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return;
        }
        if (data.username.length > 12) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối đa 12 kí tự"
            });
            return;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return;
        }
        if (data.password !== data.ippassword) {
            setError("ippassword", {
                type: "minLength",
                message: "Hai mật khẩu không khớp"
            });
            return;
        }
        if (!data.name) {
            setError("name", {
                type: "minLength",
                message: "Vui lòng nhập đầy đủ họ tên"
            });
            return;
        }
        if (!data.sdt) {
            setError("sdt", {
                type: "minLength",
                message: "Vui lòng nhập số điện thoại"
            });
            return;
        }
        if (!phoneNumberPattern.test(data.sdt)) {
            setError("sdt", {
                type: "minLength",
                message: "Định dạng số điện thoại không hợp lệ"
            });
            return;
        }
        if (data.paypwd.length < 6) {
            setError("paypwd", {
                type: "minLength",
                message: "Mật khẩu rút tiền tối thiểu 6 kí tự"
            });
            return;
        }
        if (data.code === "") {
            axios
                .post(`${process.env.REACT_APP_API_URL}/auth/register`, {
                    username: data.username.toLowerCase(),
                    password: data.password,
                    code: "admin",
                    sdt: data.sdt,
                    name: data.name,
                    paypwd: data.paypwd
                })
                .then((res) => {
                    swal({
                        title: "Thông báo",
                        text: "Đăng ký thành công",
                        icon: "success",
                        buttons: "OK"
                    }).then(() => navigate("/login"));
                })
                .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error"));
        } else if (data.code) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/auth/register`, {
                    username: data.username.toLowerCase(),
                    password: data.password,
                    code: data.code,
                    sdt: data.sdt,
                    name: data.name,
                    paypwd: data.paypwd
                })
                .then((res) => {
                    swal({
                        title: "Thông báo",
                        text: "Đăng ký thành công",
                        icon: "success",
                        buttons: "OK"
                    }).then(() => navigate("/login"));
                })
                .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error"));
        }
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [showPassword1, setShowPassword1] = useState(false);
    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };
    const [showPassword2, setShowPassword2] = useState(false);
    const toggleShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };
    return (
        <div className="login">
            <div style={{ minHeight: "50px" }}>
                {notify && (
                    <>
                        {notify[1]?.isShow && (
                            <div className="banner-login">
                                <img alt="" src={notify[1]?.image} />
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="logo-login">
                <img alt="" src="/images/logo.png" />
            </div>
            <AnimatedPage>
                <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                    <div onClick={() => navigate("/login")} className="login-return">
                        <KeyboardArrowLeftOutlinedIcon className="login-return-i" /> <span>Quay lại</span>
                    </div>
                    <div className="inputs">
                        <div className="input">
                            <img alt="" src="images/icon-user-add.svg" />
                            <input type="text" className="ip-lg" {...register("username", { required: true })} placeholder="Tên đăng nhập" />
                        </div>
                        {errors.username ? <p>{errors.username.message}</p> : null}
                        <div className="input">
                            <img alt="" src="images/icon-password.svg" />
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Mật khẩu" />
                            {showPassword ? <Visibility onClick={toggleShowPassword} /> : <VisibilityOff onClick={toggleShowPassword} />}
                        </div>
                        {errors.password ? <p>{errors.password.message}</p> : null}
                        <div className="input">
                            <img alt="" src="images/icon-password.svg" />
                            <input type={showPassword1 ? "text" : "password"} className="ip-lg" {...register("ippassword", { required: true })} placeholder="Nhập lại mật khẩu" />
                            {showPassword1 ? <Visibility onClick={toggleShowPassword1} /> : <VisibilityOff onClick={toggleShowPassword1} />}
                        </div>
                        {errors.ippassword ? <p>{errors.ippassword.message}</p> : null}
                        <div className="input">
                            <img alt="" src="images/icon-user.svg" />
                            <input type="text" className="ip-lg" {...register("name", { required: true })} placeholder="Họ và tên" />
                        </div>
                        {errors.name ? <p>{errors.name.message}</p> : null}
                        <div className="input">
                            <PhoneIcon style={{ color: "#952020", left: "1rem", right: "auto", fontSize: "0.55rem" }} />
                            <input type="number" className="ip-lg" {...register("sdt", { required: true })} placeholder="Số điện thoại" />
                        </div>
                        {errors.sdt ? <p>{errors.sdt.message}</p> : null}
                        <div className="input">
                            <img alt="" src="images/icon-password.svg" />
                            <input type={showPassword2 ? "text" : "password"} className="ip-lg" {...register("paypwd", { required: true })} placeholder="Mật khẩu rút tiền" />
                            {showPassword2 ? <Visibility onClick={toggleShowPassword2} /> : <VisibilityOff onClick={toggleShowPassword2} />}
                        </div>
                        {errors.paypwd ? <p>{errors.paypwd.message}</p> : null}
                        <div className="input">
                            <input type="code" className="ip-lg" {...register("code")} placeholder="Mã giới thiệu (nếu có)" style={{ paddingLeft: "0.50667rem" }} />
                        </div>
                        {errors.code ? <p>{errors.code.message}</p> : null}
                    </div>
                    {err ? <p>{err}</p> : null}
                    <div className="p-lg">
                        {/*<span>Quên mật khẩu?</span>*/}
                        <Link to="/login">
                            <i>Bạn đã có tài khoản?</i> Đăng nhập
                        </Link>
                    </div>
                    <button className="btn-red-big" type="submit">
                        ĐĂNG KÝ
                    </button>
                </form>
            </AnimatedPage>
        </div>
    );
}
export default Register;
