import { useEffect, useState } from "react";
//import LiveChat from "react-livechat";
import Footer from "../components/Footer";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Invite() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    //const [isShow, setShow] = useState(false);
    const [profit, setProfit] = useState(null);
    //const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/profit/get`, {}).then((res) => {
            setProfit(res.data.data[0]);
        });
        /*axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
			setSetting(res.data.data[0]);
		});*/
    }, []);
    return (
        <>
            {/*isLoading ? (
				<div className="loading">
					<div className="loader"></div>
				</div>
			) : null*/}
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Mời bạn bè</h1>
            </div>
            <div className="main">
                {profit && profile && (
                    <AnimatedPage>
                        <div className="text_choose_center huongdan" style={{ position: "relative" }}>
                            <div className="title" style={{ margin: "0.2rem 0 0.4rem", padding: "0", fontWeight: "700" }}>
                                Giới thiệu bạn bè nhận quà liền tay
                            </div>
                            <ul>
                                <li>Nhận ngay {profit ? Number(profit.first).toLocaleString() : 0}₫ khi giới thiệu thành công một thành viên mới.</li>
                                <li>Nhận ngay {profit ? Number(profit.aff).toLocaleString() : 0}₫ khi thành viên mới tham gia trò chơi lần đầu.</li>
                                {/*<li>Nhận thêm {setting ? Number(setting.aff)*100: 0}% số tiền mỗi khi thành viên của bạn giới thiệu tham gia trò chơi</li>*/}
                            </ul>
                        </div>
                        <div className="invite">
                            <div className="text-invite">
                                <h3>{profile?.username}</h3>
                                <div>Mã mời thành viên</div>
                                <h4>{profile?.code}</h4>
                            </div>
                        </div>
                    </AnimatedPage>
                )}
            </div>
            <Footer />
        </>
    );
}

export default Invite;
