import "./footer.css";
import { Link, useLocation } from "react-router-dom";

function Footer(props) {
    var profile = props.profile;
    const location = useLocation();
    const data = [
        {
            icon: "home",
            iconactive: "home-act",
            title: "Trang chủ",
            to: "/"
        },
        {
            icon: "withdraw",
            iconactive: "withdraw-act",
            title: "Rút tiền",
            to: "/withdraw"
        },
        {
            icon: "user",
            iconactive: "user-act",
            title: "Tài khoản",
            to: "/profile"
        },
        {
            icon: "recharge",
            iconactive: "recharge-act",
            title: "Nạp tiền",
            to: "/recharge"
        },
        {
            icon: "service",
            iconactive: "service-act",
            title: "CSKH",
            to: "/service"
        }
    ];

    return (
        <>
            <div className="footer">
                {data.map((item, index) => (
                    <div className={location.pathname === item.to ? "footer_item active" : "footer_item"} key={index}>
                        <Link style={{ textDecoration: "none" }} to={item.to}>
                            <div className="icon_footer">
                                {location.pathname === item.to ? (
                                    <img alt="" src={require(`../../images/footer/icon_${item.iconactive}.png`)} />
                                ) : (
                                    <img alt="" src={require(`../../images/footer/icon_${item.icon}.png`)} />
                                )}
                            </div>
                            <div className="title_footer">{item.title}</div>
                        </Link>
                    </div>
                ))}
            </div>
        </>
    );
}
export default Footer;
