import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";

function AddBank() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const [bank, setBank] = useState(null);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios
            .get(`${process.env.REACT_APP_API_URL}/bank/getBank`, {})
            .then((res) => {
                setBank(res.data.data);
                setLoad(true);
            })
            .catch((err) => setBank(null));
    }, []);
    const onSubmit = (data) => {
        const formData = {
            name_bank: data.name_bank,
            stk: data.stk,
            fullname: data.fullname,
            user: profile._id
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/bank/create`, formData)
            .then((res) => {
                swal("Thành Công", "Ngân hàng đã được thêm thành công", "success");
                navigate("/profile");
            })
            .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error"));
    };
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Liên kết ngân hàng</h1>
            </div>
            <div className="main">
                <div className="content_profile">
                    {load && (
                        <AnimatedPage>
                            {show === true ? (
                                <>
                                    <form className="form-lg form-box" onSubmit={handleSubmit(onSubmit)} style={{ margin: "0.5rem 0" }}>
                                        <div className="inputs">
                                            <div className="input">
                                                <label>Nhập tên ngân hàng</label>
                                                <input
                                                    className="ipadd"
                                                    type="text"
                                                    {...register("name_bank", {
                                                        required: true
                                                    })}
                                                />
                                            </div>
                                            <div className="input">
                                                <label>Nhập số tài khoản</label>
                                                <input
                                                    className="ipadd"
                                                    type="number"
                                                    {...register("stk", {
                                                        required: true
                                                    })}
                                                />
                                            </div>
                                            <div className="input">
                                                <label>Nhập tên chủ tài khoản</label>
                                                <input
                                                    className="ipadd"
                                                    type="text"
                                                    {...register("fullname", {
                                                        required: true
                                                    })}
                                                />
                                            </div>
                                            <button type="submit" className="btn-submit">
                                                Xác nhận
                                            </button>
                                        </div>
                                    </form>
                                </>
                            ) : null}
                            <div className="list-bank">
                                {bank != null ? (
                                    <>
                                        {bank.map((item) => (
                                            <>
                                                <div className="box-banking" onClick={() => swal("Chú ý", "Để chỉnh sửa thông tin ngân hàng vui lòng liên hệ CSKH", "info")}>
                                                    <div className="money_banking">
                                                        <h3>{item.fullname}</h3>
                                                        <h4>{item.stk.slice(0, -4).replace(/./g, '*') + item.stk.slice(-4)}</h4>
                                                    </div>
                                                    <div className="ctk">{item.name_bank}</div>
                                                    <div className="icon_credit">
                                                        <img alt="" src={require("../../img/icon_credit.png")} />
                                                    </div>
                                                </div>
                                            </>
                                        ))}

                                        <div className="text_choose_center huongdan">
                                            <div className="title" style={{ margin: "0.2rem 0 0.4rem", padding: "0", fontWeight: "700" }}>
                                                Chú ý
                                            </div>
                                            <ul>
                                                <li>Thông tin liên kết ngân hàng phải tuyệt đối chính xác.</li>
                                                <li>Chúng tôi không chịu trách nhiệm cho sai sót liên quan đến ngân hàng của bạn.</li>
                                                <li>Để chỉnh sửa thông tin ngân hàng nếu bạn đã liên kết, vui lòng liên hệ CSKH.</li>
                                            </ul>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {show === false ? (
                                            <>
                                                <div style={{ margin: "1.8rem 0 0", color: "#f7f6f6" }}>Hiện chưa có ngân hàng</div>
                                                <button
                                                    className="btn-medium"
                                                    style={{
                                                        margin: "1rem auto 0.5rem"
                                                    }}
                                                    onClick={() => setShow(!show)}>
                                                    Thêm tài khoản ngân hàng
                                                </button>
                                            </>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        </AnimatedPage>
                    )}
                </div>

                <Footer />
            </div>
        </>
    );
}
export default AddBank;
