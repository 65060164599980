import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { GetNameChoose } from "../../funcUtils";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";
import swal from "sweetalert";

function HistoryBet() {
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [history, setHistory] = useState(null);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/historyus`, {})
            .then((res) => {
                setHistory(res.data.data);
            })
            .catch((err) => function () {});
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    const XSMN = [
        "Bạc Liêu",
        "Vũng Tàu",
        "Tiền Giang",
        "Kiên Giang",
        "Đà Lạt",
        "Bình Phước",
        "Bình Dương",
        "An Giang",
        "Bình Thuận",
        "Cà Mau",
        "Cần Thơ",
        "Hậu Giang",
        "Đồng Tháp",
        "Tây Ninh",
        "Vĩnh Long",
        "Trà Vinh",
        "Sóc Trăng",
        "Long An",
        "TP. HCM",
        "Đồng Nai",
        "Bến Tre"
    ];
    const XSMT = [
        "Đà Nẵng",
        "Thừa T. Huế",
        "Quảng Trị",
        "Phú Yên",
        "Quảng Bình",
        "Quảng Nam",
        "Quảng Ngãi",
        "Ninh Thuận",
        "Kon Tum",
        "Khánh Hòa",
        "Gia Lai",
        "Bình Định",
        "Đắk Lắk",
        "Đắk Nông"
    ];
    const checkCancel = (item) => {
        const datexs = new Date(item.id_bet);
        const ngayxs = datexs.getDate();
        const thangxs = datexs.getMonth() + 1;
        const namxs = datexs.getFullYear();
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const formattedDate = `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
        const minute = currentDate.getMinutes() < 10 ? "0" + currentDate.getMinutes() : currentDate.getMinutes();

        if (item.sanh === "XSMB" && item?.id_bet == formattedDate && Number(currentDate.getHours() + "" + minute) >= 1800) {
            console.log("a");
            return 0;
        } else if (XSMT.includes(item?.sanh) && item?.id_bet == formattedDate && Number(currentDate.getHours() + "" + minute) >= 1700) {
            console.log("b");
            return 0;
        } else if (XSMN.includes(item?.sanh) && item?.id_bet == formattedDate && Number(currentDate.getHours() + "" + minute) >= 1600) {
            console.log("c");
            return 0;
        } else {
            return 1;
        }
    };
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Lịch sử tham gia</h1>
            </div>
            <div className="main">
                {history != null ? (
                    <div className="content-history" style={{ margin: "0.2rem 0 0" }}>
                        <AnimatedPage>
                            {history?.map((item, key) => (
                                <>
                                    <div
                                        className="item_inner"
                                        onClick={() => {
                                            setLs(item);
                                            setShow(true);
                                        }}>
                                        <div className="item_history">
                                            <div className="title_item_history">
                                                <span className="sanh">{item?.sanh === "XSMB" ? "Miền Bắc" : item?.sanh}</span>
                                                <span
                                                    className={`type_state ${
                                                        item?.status_bet === "Pending"
                                                            ? "pending"
                                                            : item?.status_bet === "Win"
                                                            ? "win"
                                                            : item?.status_bet === "Lose"
                                                            ? "lose"
                                                            : "cancelled"
                                                    }`}>
                                                    {item?.status_bet === "Pending"
                                                        ? "Chờ xổ"
                                                        : item?.status_bet === "Win"
                                                        ? "Thắng"
                                                        : item?.status_bet === "Lose"
                                                        ? "Thua"
                                                        : "Đã hủy"}
                                                </span>
                                                {item?.status_bet === "Pending" && (
                                                    <>
                                                        {checkCancel(item) && (
                                                            <span
                                                                className="cancel-bet type_state"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.target.style.display = "none";
                                                                    const currentDate = new Date();
                                                                    const day = currentDate.getDate();
                                                                    const month = currentDate.getMonth() + 1;
                                                                    const year = currentDate.getFullYear();
                                                                    const formattedDate = `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
                                                                    const minute = currentDate.getMinutes() < 10 ? "0" + currentDate.getMinutes() : currentDate.getMinutes();
                                                                    if (
                                                                        item.sanh === "XSMB" &&
                                                                        Number(currentDate.getHours() + "" + minute) >= 1800 &&
                                                                        Number(currentDate.getHours() + "" + minute) < 1915
                                                                    ) {
                                                                        swal("Thông báo", "Chỉ được hủy cược trước 30p xổ", "error");
                                                                        e.stopPropagation();
                                                                        return;
                                                                    } else if (
                                                                        XSMT.includes(item?.sanh) &&
                                                                        item?.id_bet == formattedDate &&
                                                                        Number(currentDate.getHours() + "" + minute) >= 1700 &&
                                                                        Number(currentDate.getHours() + "" + minute) < 1815
                                                                    ) {
                                                                        swal("Thông báo", "Chỉ được hủy cược trước 30p xổ", "error");
                                                                        e.stopPropagation();
                                                                        return;
                                                                    } else if (
                                                                        XSMN.includes(item?.sanh) &&
                                                                        item?.id_bet == formattedDate &&
                                                                        Number(currentDate.getHours() + "" + minute) >= 1600 &&
                                                                        Number(currentDate.getHours() + "" + minute) < 1715
                                                                    ) {
                                                                        swal("Thông báo", "Chỉ được hủy cược trước 30p xổ", "error");
                                                                        e.stopPropagation();
                                                                        return;
                                                                    }
                                                                    axios
                                                                        .patch(`${process.env.REACT_APP_API_URL}/history/cancel`, {
                                                                            id: item?._id,
                                                                            userId: profile?._id,
                                                                            money: item?.money
                                                                        })
                                                                        .then((res) => {
                                                                            setShow(false);
                                                                            swal("Hủy cược thành công", "", "success").then((value) => {
                                                                                axios
                                                                                    .get(`${process.env.REACT_APP_API_URL}/history/historyus`, {})
                                                                                    .then((res) => {
                                                                                        setHistory(res.data.data);
                                                                                    })
                                                                                    .catch((err) => {});
                                                                            });
                                                                        })
                                                                        .catch((err) => {
                                                                            setShow(false);
                                                                            swal("Thông báo", "Đã xảy ra lỗi, vui lòng thử lại!", "error");
                                                                            e.target.style.display = "inline-block";
                                                                        });
                                                                }}>
                                                                &times; Hủy cược
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="id_history_sanh">Phiên cược: {item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}</div>
                                            <div className="id_history_sanh">
                                                {GetNameChoose(item?.state, item?.type, item?.sanh) ? GetNameChoose(item?.state, item?.type, item?.sanh) : "Chọn " + item?.state}
                                            </div>
                                        </div>
                                        <div className="money_history">
                                            <span className="money">{Number(item?.money).toLocaleString()}₫</span>
                                            <div className="time_choose">{formatDate(new Date(item?.createdAt))}</div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </AnimatedPage>
                    </div>
                ) : null}

                <Footer profile={profile} />

                {isShow === true && ls?.status_bet !== "Pending" ? (
                    <>
                        <div className="modal" style={{ zIndex: "9999999" }}>
                            <div className="modaloverlay">
                                <i className="ti-close closelogin"></i>
                            </div>
                            <div className="modalbody">
                                <div>
                                    <div className="modalinner" style={{ padding: "10px 15px" }}>
                                        <div className="modalheader" style={{ padding: "10px 0 20px" }}>
                                            Chi tiết cược
                                        </div>

                                        {ls?.id_bet?.id_bet || ls?.id_bet ? (
                                            <>
                                                <div className="lsgd-table">
                                                    <div>Trò chơi</div>
                                                    <div>{ls?.sanh}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Phiên</div>
                                                    <div>{ls?.id_bet?.id_bet ? ls?.id_bet?.id_bet : ls?.id_bet ? ls?.id_bet : "Không xác định"}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Thời gian</div>
                                                    <div>{formatDate(new Date(ls?.createdAt))}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Đặt cược</div>
                                                    <div>{GetNameChoose(ls?.state, ls?.type, ls?.sanh) ? GetNameChoose(ls?.state, ls?.type, ls?.sanh) : "Chọn " + ls?.state}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Tổng Cược</div>
                                                    <div>{Number(ls?.money).toLocaleString()} ₫</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Tổng thắng</div>
                                                    <div>{Number(ls?.moneythang).toLocaleString()} ₫</div>
                                                </div>
                                            </>
                                        ) : null}
                                        <div>
                                            <div className="modalformcontrols">
                                                <button
                                                    onClick={() => setShow(false)}
                                                    className="popup-close"
                                                    style={{
                                                        background: "#0064ff",
                                                        boxShadow: "none",
                                                        textShadow: "none"
                                                    }}>
                                                    ĐÓNG
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
}
export default HistoryBet;
